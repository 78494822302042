<ng-container *ngIf="subNavigationItems$ | async as subNavigationItems">
  <app-sub-navigation *ngIf="subNavigationItems?.length" [items]="subNavigationItems" (closeSubNavigationEvent)="closeSubNavigation()" />
</ng-container>
<div class="navigation flex items-center gap-24">
  <ng-container *ngFor="let item of items">
    <ng-container *ngIf="item?.items?.length; else link">
      <div class="navigation__item text-14 cursor-pointer" (click)="openItems(item)">
        <span>
          {{ item.label }}
        </span>
        <mat-icon [svgIcon]="svgIcons.NAV_ARROW" />
      </div>
    </ng-container>
    <ng-template #link>
      <a class="navigation__item text-14" [routerLink]="item.url">
        <span>
          {{ item.label }}
        </span>
      </a>
    </ng-template>
  </ng-container>

  <div [matMenuTriggerFor]="menu" class="navigation__burger flex items-center gap-4 ml-auto">
    <mat-icon [svgIcon]="svgIcons.BURGER" />
  </div>
</div>

<mat-menu #menu="matMenu">
  <a mat-menu-item (click)="menuEvent('profile')" class="menu__item">Mій профіль</a>
  <div *ngFor="let item of items" [routerLink]="item.url" class="item">
    <a mat-menu-item [routerLink]="item.url" class="menu__item">{{ item.label }}</a>
  </div>
  <button mat-menu-item (click)="menuEvent('logout')" class="menu__item">Вийти</button>
</mat-menu>

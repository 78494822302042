import { timer } from 'rxjs'
import { NavigationItem } from 'src/app/common/header/components/navigation/shared/types'

import { animate, style, transition, trigger } from '@angular/animations'
import { NgFor, NgIf } from '@angular/common'
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { RouterLink } from '@angular/router'

import { ClickOutsideDirective } from 'src/app/core/directives/click-outside.directive'

export const slideInOutAnimation = trigger('slideInOut', [
  transition(':enter', [
    style({ transform: 'translateY(-100%)', opacity: 0 }),
    animate('200ms ease-in', style({ transform: 'translateY(0%)', opacity: 1 })),
  ]),
  transition(':leave', [
    style({ transform: 'translateY(0%)', opacity: 1 }),
    animate('200ms ease-in', style({ transform: 'translateY(-100%)', opacity: 0 })),
  ]),
])

@Component({
  selector: 'app-sub-navigation',
  standalone: true,
  imports: [RouterLink, NgFor, ClickOutsideDirective, NgIf],
  templateUrl: './sub-navigation.component.html',
  styleUrl: './sub-navigation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideInOutAnimation],
})
export class SubNavigationComponent {
  @Input() items: NavigationItem[] = []

  @Output() closeSubNavigationEvent: EventEmitter<void> = new EventEmitter<void>()

  isVisible = true

  hide(): void {
    this.isVisible = !this.isVisible
    timer(150).subscribe(() => {
      this.clickOutside()
    })
  }

  clickOutside(): void {
    this.closeSubNavigationEvent.emit()
  }
}

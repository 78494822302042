<div
  *ngIf="isVisible"
  [@slideInOut]
  class="sub bg-background-main pb-56 absolute top-82 left-0 w-full z-10"
  appClickOutside
  (appClickOutside)="hide()"
>
  <div class="wrapper">
    <div class="flex flex-wrap gap-16 mt-56">
      <a *ngFor="let link of items" class="sub__item text-16" [routerLink]="link.url">{{ link.label }}</a>
    </div>
  </div>
</div>

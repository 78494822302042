import { filter, fromEvent, skip } from 'rxjs'

import { Directive, ElementRef, EventEmitter, Output } from '@angular/core'

@Directive({
  selector: '[appClickOutside]',
  standalone: true,
})
export class ClickOutsideDirective {
  @Output() appClickOutside = new EventEmitter<Event>()

  constructor(private elementRef: ElementRef) {
    fromEvent<MouseEvent>(document, 'click')
      .pipe(
        skip(1),
        filter((event) => this.isOutside(event)),
      )
      .subscribe(() => {
        this.appClickOutside.emit()
      })
  }

  private isOutside(event: MouseEvent): boolean {
    const clickedInside = this.elementRef.nativeElement.contains(event.target)

    return !clickedInside
  }
}
